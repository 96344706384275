<template>
  <content>
    <b-row>
      <b-col cols="12">
        <bread-crumbs :currentpage="currentPage" />
      </b-col>
      <b-col cols="12">
        <warehouse-selector />
      </b-col>

      <b-col md="12" lg="3">
        <div v-if="false">
          <label for="">{{ $t('item.sortBy') }}</label>
          <b-form-select
            id="sortOption"
            v-model="sortOption"
            :options="sortOptions"
            size="sm"
          ></b-form-select>
          <hr />
        </div>
        <sidebar :categories="categoryList" />
      </b-col>
      <b-col>
        <h1 v-if="!searchTerm" class="text-center">
          {{ currentCategory.Description }}
        </h1>
        <h1 v-else class="text-center">
          {{ $t('item.searchFor') }} {{ searchTerm }}
        </h1>
        <b-row align-h="end">
          <b-col lg="3" md="0" class="d-none d-lg-block text-right mt-2">
            <b-button-group class="">
              <b-button
                :class="{ disabled: !gridListing }"
                @click="toggleGridListing"
              >
                <font-awesome-icon :icon="['fas', 'th-list']" />
              </b-button>
              <b-button
                :class="{ disabled: gridListing }"
                @click="toggleGridListing"
              >
                <font-awesome-icon :icon="['fas', 'th']" />
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="12"> </b-col>
        </b-row>

        <p
          v-if="currentCategory.Description && !searchTerm"
          v-html="currentCategory.Notes"
        />

        <b-row v-if="gridListing" class="row">
          <b-col
            v-for="item in items"
            :key="item.ID"
            xs="12"
            md="6"
            class="mt-4"
          >
            <Item :item="item" />
          </b-col>
        </b-row>
        <b-row v-if="!gridListing" class="row">
          <b-col v-for="item in items" :key="item.ID" cols="12">
            <item-horizontal :item="item" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </content>
</template>

<script>
// @ is an alias to /src
import { getItems } from '@/services/ItemService'
import Item from '@/components/items/Item'
import ItemHorizontal from '@/components/items/Item-horizontal'
import { getCategories, getCategory } from '@/services/CategoryService'
import SideBar from '@/components/sidebar/sidebar'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import WarehouseSelector from '@/components/global/WarehouseSelector'

export default {
  name: 'Items',
  components: {
    Item,
    ItemHorizontal,
    sidebar: SideBar,
    WarehouseSelector
  },

  props: {
    searchTerm: {
      type: String,
      required: false,
      default: function() {
        return ''
      }
    }
  },
  events: {
    confirm: function(argument) {
      this.calendar.startDate = argument
    },
    checkOutChanged: function(argument) {
      this.calendar.endDate = argument
    }
  },
  data() {
    return {
      items: [],
      index: 1,
      size: 900,
      total: 1,
      gettingData: false,
      categories: [],
      currentCategory: '',
      breadCrumbs: [],
      dateSelection: '',
      sortOption: null,
      sortOptions: [
        { value: null, text: this.$t('item.sort.default') },
        { value: 'az', text: 'A-Z' },
        { value: 'za', text: 'Z-A' },
        { value: 'price-low-high', text: this.$t('item.sort.pricelowhigh') },
        { value: 'price-high-low', text: this.$t('item.sort.pricehighlow') }
      ]
    }
  },
  metaInfo() {
    return {
      title: `${this.currentCategory.Description ?? 'Home'}`
    }
  },
  computed: {
    currentPage() {
      if (this.currentCategory !== '' && !this.searchTerm) {
        return this.currentCategory.Description
      }

      if (this.searchTerm) {
        return `${this.$t('search-for')} ${this.searchTerm}`
      }

      return ''
    },
    pages: function() {
      return Math.ceil(this.total / this.size)
    },
    categoryList() {
      if (this.categories.length) {
        return this.categories
      }
      return this.$store.state.categories
    },
    categoryID: function() {
      if (this.$route.params.categoryID != undefined) {
        return this.$route.params.categoryID
      } else {
        return process.env.VUE_APP_DEFAULT_CATEGORY_ID
      }
    },
    gridListing: function() {
      return this.$store.getters.gridListing
    }
  },
  watch: {
    $route: function() {
      this.loadData()
    },
    index: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ index: val })

      this.gettingData = false
    },
    sortOption: async function(val) {
      let orderBy = null
      let sortReverse = null

      if (val === 'az') {
        orderBy = 'Description'
        sortReverse = false
      }

      if (val === 'za') {
        orderBy = 'Description'
        sortReverse = true
      }

      if (val === 'price-low-high') {
        orderBy = 'WeekPrice'
        sortReverse = true
      }

      if (val === 'price-high-low') {
        orderBy = 'WeekPrice'
        sortReverse = false
      }

      this.updateItemData({ orderBy, sortReverse })
    },
    categoryID: async function() {
      this.items = []

      await this.updateCurrentCategory()
      let categories = await getCategories()
      if (categories.Collection) {
        this.categories = categories.Collection
      } else {
        this.categories = categories
      }
      if (this.searchTerm && this.searchTerm.length > 1) {
        this.breadCrumbs = await generateBreadCrumbs({
          categoryID: this.currentCategory.categoryID
        })
      }
      await this.updateItemData()
    },
    searchTerm: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ search: val })

      this.gettingData = false
    }
  },
  async created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      await this.updateCurrentCategory()
      let categories = await getCategories()
      if (categories.Collection) {
        this.categories = categories.Collection
      } else {
        this.categories = categories
      }
      this.breadCrumbs = await generateBreadCrumbs({
        categoryID: this.currentCategory.categoryID
      })
      await this.updateItemData()
    },
    async updateCurrentCategory() {
      let result
      if (this.categoryID) {
        result = await getCategory({
          categoryID: this.categoryID,
          slug: false
        })
      }
      if (result.categoryID === undefined) {
        if (this.categoryID) {
          this.currentCategory = await getCategory({
            categoryID: this.categoryID
          })
        }
        return
      }
      this.currentCategory = result.categoryID
    },
    async updateItemData({
      size = this.size,
      index = this.index,
      search = this.searchTerm,
      categoryID = this.currentCategory.CategoryID,
      orderBy = null,
      sortReverse = null
    } = {}) {
      if (search != null) {
        categoryID = null
      }
      let items = await getItems({
        size,
        index,
        search,
        categoryID,
        orderBy,
        sortReverse,
        warehouseID: this.$store.state.activeWarehouse
      })

      this.items = items.data.Collection

      this.size = items.data.Size
      this.total = items.data.Total
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    toggleGridListing() {
      this.$store.commit('toggleGridListing')
    }
  }
}
</script>
