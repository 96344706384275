<template>
  <div :class="`card card-product mt-5 ${additionalItemClasses}`">
    <div class="card-body">
      <b-row>
        <b-col xs="12" md="6" lg="5">
          <div v-if="imageUrl" @click="navigateToItem">
            <vue-clazy-load v-if="imageUrl" :src="imageUrl">
              <!-- The image slot renders after the image loads. -->
              <transition name="fade">
                <img
                  :alt="item.description"
                  :src="`${imageUrl}`"
                  crossorigin="anonymous"
                  class="rounded"
                />
              </transition>
              <!-- The placeholder slot displays while the image is loading. -->
              <transition slot="placeholder" name="fade">
                <!-- You can put any component you want in here. -->
                <img
                  v-if="imageUrl"
                  alt="Placeholder"
                  crossorigin="anonymous"
                  class="rounded"
                  src="/img/placeholder.jpg"
                />
              </transition>
            </vue-clazy-load>

            <img
              v-if="!imageUrl && !imageUrls"
              class="d-block mx-auto"
              src="/img/itemFallback.png"
            />
          </div>
          <div
            v-if="item.Type === 'Composition' && !imageUrl"
            class="card-img-top p-2 mt-2"
          >
            <ImageGrid :images="compostionImages" />
          </div>
        </b-col>
        <b-col xs="12" md="6" lg="7">
          <a v-if="item.Brand" class="product-brand" href="#">{{
            item.Brand
          }}</a>

          <router-link :to="to">
            <h3 class="mt-2 mt-md-0">{{ item.Description }}</h3>
          </router-link>
          <div class="mb-2">
            <item-description :to="to" :description="item.MediumDescription" />
          </div>

          <div v-if="price" class="d-flex justify-content-between">
            <div class="product-price">
              <p>
                <strong>{{ price }} {{ $t('item.euroRentalPrice') }}</strong>
              </p>
            </div>
          </div>
          <router-link
            :to="to"
            class="btn btn-outline-primary mb-2 mr-2"
            data-toggle="toast"
            data-target="#cart-toast"
          >
            <i class="czi-cart font-size-sm mr-1"></i
            >{{ $t('item.openDetails') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageGrid from '@/components/items/ImageGrid'
import ItemDescription from './ItemDescription'
import { VueClazyLoad } from 'vue-clazy-load'

export default {
  name: 'Item',
  components: {
    ImageGrid,
    ItemDescription,
    VueClazyLoad
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    availableInWarehouse: function() {
      if (this.$store.state.activeWarehouse === '') return true
      if (this.item.WarehouseIDs.includes(this.$store.state.activeWarehouse))
        return true
      return false
    },
    additionalItemClasses: function() {
      if (!this.availableInWarehouse) {
        return 'unavailable'
      } else {
        return ''
      }
    },
    itemPrefixUrlPath: function() {
      if (this.$route.params.categoryID) return this.$route.path
      if (this.$route.path.includes('zoeken'))
        return `/${this.$i18n.locale}/shop`
      return `/${this.$i18n.locale}/shop/${process.env.VUE_APP_DEFAULT_CATEGORY_ID}`
    },
    image: function() {
      if (this.item.Images && this.item.Images?.length >= 1) {
        return this.item.Images[0]
      } else {
        return null
      }
    },
    user: function() {
      return this.$store.getters.user
    },
    to: function() {
      return { path: this.routePath, params: { lang: this.$i18n.locale } }
    },
    routePath: function() {
      if (this.suggestedItem) return `/shop/artikel/${this.item.ID}`
      return `${this.itemPrefixUrlPath}/artikel/${this.item.ID}`
    },
    imageUrl: function() {
      if (this.item.Images?.length > 0) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0]}`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = this.item.Items.map(
          item => process.env.VUE_APP_URL + item.DefaultImage.ImageUrl
        )
        return images.slice(0, 4)
      }
      return null
    },
    imageUrls: function() {
      if (this.item.Items) {
        return this.item.Items.map(a => a.ImageUrl)
      }
      return ''
    },

    price: function() {
      if (!this.item.ItemPeriods) return null
      if (this.item.ItemPeriods[1]) {
        return this.item.ItemPeriods[1].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      if (this.item.ItemPeriods[0]) {
        return this.item.ItemPeriods[0].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      return null
    }
  },
  methods: {
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },
    navigateToItem: function() {
      window.location = this.routePath
    }
  }
}
</script>
<style scoped lang="scss">
.unavailable {
  filter: grayscale(100%) opacity(50%);
}
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  br {
    display: none;
  }
  img {
    width: 100%;
  }
}

.card-product {
  border: none;
  a {
    text-decoration: none;
  }
}

.product-title {
  min-height: 50px;
}

h3 {
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
  text-decoration: none;
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;
}
</style>
