<template>
  <div class="w-100 d-none">
    <p>{{ this.$t('select-location') }}</p>
    <div class="warehouse-selectors">
      <div>
        <b-button
          v-for="warehouse of warehouses"
          :key="warehouse.value"
          @click="changeWarehouse(warehouse.value)"
          :disabled="activeWarehouse === warehouse.value"
          size="sm"
          class="ml-2 mb-2"
          >{{ warehouse.text }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    warehouses: function() {
      return [
        {
          value: '',
          text: this.$t('choose-retrieval-location'),
          disabled: true
        },
        { value: 'Fietspunt Dampoort', text: this.$t('Fietspunt Dampoort') },
        {
          value: 'Fietspunt Gent Sint-Pieters',
          text: this.$t('Fietspunt Gent Sint-Pieters')
        },
        {
          value: 'Fietspunt Onder de Krook',
          text: this.$t('Fietspunt Onder de Krook')
        }
      ]
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    }
  },
  methods: {
    changeWarehouse: function(warehouseID) {
      this.$store.commit('setActiveWarehouseID', { warehouseID })
    }
  }
}
</script>

<style scoped>
.warehouse-selectors {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}
</style>
