<template>
  <div class="sidebar">
    <b-button class="pl-0 ml-0 side-cat_title" variant="link">{{
      $t('sidebar.categories')
    }}</b-button>
    <div class="mt-2">
      <div>
        <ul class="side-cats">
          <li v-for="category in categories" :key="category.CategoryID">
            <sidebar-category :category="category" :categories="categories" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarCategory from './sidebarCategory'

export default {
  components: {
    sidebarCategory
  },
  props: {
    categories: {
      required: true,
      type: Array,
      default: function() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 767px) {
  .sidebar {
    display: block !important;
  }
}

.sidebar {
  display: none;
}

.side-cat_title {
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0px;
  width: 100%;
  position: relative;
  /* background: #f3f3f3; */
  padding: 13px;
  padding-left: 0;
  color: #333333;
  font-family: 'Open Sans', Helvetica, sans-serif;
  text-decoration: none;
  text-align: left;
}
ul.side-cats {
  font-weight: bold;
  list-style: none;
  padding-left: 0px;
  li > div > a {
    display: block;
    padding: 0;
    color: #333333 !important;
    min-height: 50px;
    line-height: 50px;
    padding-right: 55px;

    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
}
</style>
