<template>
  <div v-if="!category.ParentCategoryID" class="categories">
    <router-link
      :to="`/${$i18n.locale}/shop/${category.CategoryID}`"
      :class="{
        'text-primary': currentCategory == category.CategoryID
      }"
      >{{ category.Description }}
    </router-link>
    <ul
      v-for="subcategory in subcategories"
      :key="subcategory.CategoryID"
      class="subcategories"
    >
      <li>
        <router-link
          :to="`/${$i18n.locale}/shop/${subcategory.CategoryID}`"
          :class="{
            'text-primary': currentCategory == subcategory.CategoryID
          }"
          >{{ subcategory.Description }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    },
    categories: {
      type: Array,
      required: true,
      default: function() {
        return []
      }
    }
  },
  computed: {
    currentCategory: function() {
      if (this.$route.params.categoryID) return this.$route.params.categoryID
      return ''
    },
    subcategories: function() {
      return this.categories.filter(
        subCategory => subCategory.ParentCategoryID === this.category.CategoryID
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.categories {
  border-bottom: 1px solid #ececec;
  a {
    display: block;
    padding: 0;
    color: #333333 !important;
    min-height: 50px;
    line-height: 50px;
    padding-right: 55px;
    //border-bottom: 1px solid #ececec;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
  a.text-primary {
    color: #1fa9ae !important;
  }

  .subcategories {
    list-style: none;
    padding-left: 20px;

    a {
      font-weight: normal;
      border: none;
      line-height: 35px;
      min-height: 37px;
    }
    li {
      border: none;
    }
  }
}
</style>
